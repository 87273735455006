import { ReactElement, useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { AppCookie } from 'utils/cookies'
import { idIsInVar } from 'utils/featureFlag'
import { paymentsDomainLocalFix } from 'utils/window'

const ErrorLayout = dynamic(() => import('@/layouts/ErrorLayout'))
import { useCompanyAndInvoiceContext } from '@/hooks/providers/CompanyAndInvoiceProvider'

/**
 * Boundary used to make sure partner
 * is enabled for using financing link,
 * and if not on their domain, redirect to it
 */

const PartnerBoundary = ({ children }: { children: ReactElement }) => {
  const [enabled, setEnabled] = useState(true)

  const { asPath } = useRouter()
  const { company, error, loading } = useCompanyAndInvoiceContext()

  const token = Cookies.get(AppCookie.TokenCustomer)
  const refreshToken = Cookies.get(AppCookie.RefreshCustomer)
  const customerId = Cookies.get(AppCookie.CustomerId)

  useEffect(() => {
    if (!company) return

    const maintenanceModePage = '/under-maintenance'
    if (
      // eslint-disable-next-line alternative/no-invalid-env-usage
      idIsInVar(company?.id, process.env.NEXT_PUBLIC_MAINTENANCE_MODE) &&
      window.location.pathname !== maintenanceModePage
    ) {
      window.location.href = maintenanceModePage
    }
  }, [company])

  useEffect(() => {
    if (error) setEnabled(false)
    if (company === null || (company && !company?.paymentsPortalEnabled))
      setEnabled(false)

    // if partner has custom portal domain but the user is not on it, redirect
    if (
      company?.paymentsPortalEnabled &&
      paymentsDomainLocalFix !== company?.paymentsPortalUrl
    ) {
      window.location.href = `http://${company?.paymentsPortalUrl}/custom-portal?token=${token}&refreshToken=${refreshToken}&customerId=${customerId}&redirect=${asPath}`
    }
    return () => setEnabled(true)
  }, [company, error])

  if (enabled && company) return <>{children}</>

  if (!enabled && !loading) {
    return <ErrorLayout />
  }
  return null
}

export default PartnerBoundary
