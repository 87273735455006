import dayjs from 'dayjs'
import {
  CustomerFindInvoiceFragment,
  InvoiceStatus
} from 'graph/generated/payments/graphql-types'
import { useRouter } from 'next/router'

import useActiveUserInfo from '@/hooks/useActiveUserInfo'

const useCustomInvoiceData = (): CustomerFindInvoiceFragment => {
  const { customer } = useActiveUserInfo()
  const {
    query: { amount }
  } = useRouter()

  const amountNumber = Number(amount) / 100
  const today = dayjs().format('YYYY-MM-DD')

  return {
    __typename: 'Invoice',
    id: '',
    invoiceNumber: '',
    amount: amountNumber,
    outstandingAmount: amountNumber,
    status: InvoiceStatus.Upcoming,
    createdAt: today,
    dueDate: today,
    customer: {
      __typename: 'Customer',
      id: customer?.id,
      name: customer?.name
    },
    offer: null,
    lending: null,
    productName: '',
    recurringpayment: false,
    directPayment: null,
    totalTaxAmount: 0,
    lineItems: [],
    platformName: '',
    totalDiscountAmount: 0
  }
}

export default useCustomInvoiceData
