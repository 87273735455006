import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { paymentVars, termsVars } from 'utils/apollo/cache'

export const paymentsCache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        invoiceTerms: {
          read() {
            return termsVars()
          }
        },
        paymentMethod: {
          read() {
            return paymentVars()
          }
        },

        CustomerInvoiceConnection: relayStylePagination(args =>
          Object.keys(args).filter(key => key !== 'first' && key !== 'after')
        ),
        CustomerPaymentConnection: relayStylePagination(args =>
          Object.keys(args).filter(key => key !== 'first' && key !== 'after')
        )
      }
    }
  }
})
